import { users } from '../api/users';
import { deviceDetection } from '../helpers/device';
import { ConfigService } from './config-service';
import { SELECT_SERVICE_KEY } from './select-service';
import { ACCORDIONS_SERVICE_KEY } from './accordion-service';
import { CHECKLIST_SERVICE_KEYS } from './checklist-service';
import { StorageListenerService } from './storage-listener-service';

export const USER_SERVICE_KEYS = {
  TOKEN: 'token',
  ACCESS_TOKEN: 'accessToken',
  REFRESH_TOKEN: 'refreshToken',
  SHADOW_ID: 'shadowId',
  HAS_ACCOUNT: 'hasAccount',
  HAS_SESSION: 'hasSession',
  EMAIL: 'email',
  CURRENT_DEVICE: 'currentDevice',
  FIREBASE_TOKEN: 'firebaseToken'
};

export class UserService {
  // There are a few items that always stored in the localStorage by default.
  static storage = localStorage;
  static tokenStorage = localStorage;
  static logoutTimerId = 0;

  static getLogoutTimerId = () => {
    return this.logoutTimerId;
  };

  static setLogoutTimerId = newLogoutTimerId => {
    this.logoutTimerId = newLogoutTimerId;
  };

  static initialize = () => {
    // Listen to the changes in the hasAccount key to remove the user data when the user logs out in different tabs
    StorageListenerService.addStorageListener(USER_SERVICE_KEYS.HAS_ACCOUNT, this.onHasAccountChange);

    const hasSession = this.storage.getItem(USER_SERVICE_KEYS.HAS_SESSION);
    if (hasSession && !this.hasUser()) {
      this.setHasAccount();
    }

    if (this.hasAccount()) {
      this.logout();
    }

    const clearAfterSession = ConfigService.get('ACCOUNT.CONFIGURATION.clearAfterSession');
    this.tokenStorage = clearAfterSession ? sessionStorage : localStorage;

    this.transferTokens(clearAfterSession);
  };

  // Backward compatibility for already logged-in users.
  static transferTokens = clearAfterSession => {
    if (!this.hasUser()) return;

    const token = localStorage.getItem(USER_SERVICE_KEYS.TOKEN);
    const accessToken = localStorage.getItem(USER_SERVICE_KEYS.ACCESS_TOKEN);
    const refreshToken = localStorage.getItem(USER_SERVICE_KEYS.REFRESH_TOKEN);
    const isLocalStorageTokens = Boolean(token && accessToken && refreshToken);

    if (clearAfterSession && isLocalStorageTokens) {
      this.tokenStorage.setItem(USER_SERVICE_KEYS.TOKEN, token);
      this.tokenStorage.setItem(USER_SERVICE_KEYS.ACCESS_TOKEN, accessToken);
      this.tokenStorage.setItem(USER_SERVICE_KEYS.REFRESH_TOKEN, refreshToken);

      this.setHasSession();

      localStorage.removeItem(USER_SERVICE_KEYS.TOKEN);
      localStorage.removeItem(USER_SERVICE_KEYS.ACCESS_TOKEN);
      localStorage.removeItem(USER_SERVICE_KEYS.REFRESH_TOKEN);
    }
  };

  static getItem = key => {
    return this.storage.getItem(key);
  };

  static setItem = (key, value) => {
    this.storage.setItem(key, value);
  };

  static removeItem = key => {
    this.storage.removeItem(key);
  };

  static removeTokens = () => {
    // We need to remove tokens from both storages
    // as on session error no user if fetched from the server and config is not possible to load
    // thus - cannot define what the tokenStorage is

    localStorage.removeItem(USER_SERVICE_KEYS.TOKEN);
    localStorage.removeItem(USER_SERVICE_KEYS.ACCESS_TOKEN);
    localStorage.removeItem(USER_SERVICE_KEYS.REFRESH_TOKEN);
    sessionStorage.removeItem(USER_SERVICE_KEYS.TOKEN);
    sessionStorage.removeItem(USER_SERVICE_KEYS.ACCESS_TOKEN);
    sessionStorage.removeItem(USER_SERVICE_KEYS.REFRESH_TOKEN);
  };

  static setTokens = data => {
    this.tokenStorage.setItem(USER_SERVICE_KEYS.TOKEN, data.token);
    this.tokenStorage.setItem(USER_SERVICE_KEYS.ACCESS_TOKEN, data.accessToken);
    this.tokenStorage.setItem(USER_SERVICE_KEYS.REFRESH_TOKEN, data.refreshToken);
    this.setHasSession();
  };

  static removeShadowUser = () => {
    this.storage.removeItem(USER_SERVICE_KEYS.SHADOW_ID);
    this.storage.removeItem(USER_SERVICE_KEYS.EMAIL);
  };

  static login = async data => {
    this.removeShadowUser();
    this.setTokens(data);

    await this.updateFirebaseToken(data);
  };

  static setHasAccount = () => {
    this.storage.setItem(USER_SERVICE_KEYS.HAS_ACCOUNT, 'true');
  };

  static removeHasAccount = () => {
    this.storage.removeItem(USER_SERVICE_KEYS.HAS_ACCOUNT);
  };

  static setHasSession = () => {
    this.storage.setItem(USER_SERVICE_KEYS.HAS_SESSION, 'true');
  };

  static removeHasSession = () => {
    this.storage.removeItem(USER_SERVICE_KEYS.HAS_SESSION);
  };

  static logout = () => {
    this.removeTokens();
    this.removeShadowUser();
    this.removeHasSession();
    this.setHasAccount();
  };

  static delete = () => {
    this.removeTokens();
    this.removeShadowUser();
    this.removeHasAccount();
    this.removeHasSession();
    this.removePersonalizedUISettings();
  };

  static removePersonalizedUISettings = () => {
    localStorage.removeItem(CHECKLIST_SERVICE_KEYS.CHECKLIST);
    localStorage.removeItem(ACCORDIONS_SERVICE_KEY);
    localStorage.removeItem(SELECT_SERVICE_KEY);
  };

  static getToken = () => {
    // There are a few cases were used before the application knew which exact token storage was defined.
    return localStorage.getItem(USER_SERVICE_KEYS.TOKEN) || sessionStorage.getItem(USER_SERVICE_KEYS.TOKEN);
  };

  static getTokens = () => {
    return {
      token: this.tokenStorage.getItem(USER_SERVICE_KEYS.TOKEN),
      accessToken: this.tokenStorage.getItem(USER_SERVICE_KEYS.ACCESS_TOKEN),
      refreshToken: this.tokenStorage.getItem(USER_SERVICE_KEYS.REFRESH_TOKEN)
    };
  };

  static hasUser = () => {
    return Boolean(this.getToken());
  };

  static hasShadowUser = () => {
    return Boolean(this.storage.getItem(USER_SERVICE_KEYS.SHADOW_ID));
  };

  static setUserShadowId = shadowId => {
    return this.storage.setItem(USER_SERVICE_KEYS.SHADOW_ID, shadowId);
  };

  static hasAccount = () => {
    return Boolean(
      this.storage.getItem(USER_SERVICE_KEYS.HAS_ACCOUNT) ||
        (this.storage.getItem(USER_SERVICE_KEYS.HAS_SESSION) && !this.hasUser())
    );
  };

  static detectUserDevice = () => {
    //Using session storage allows the same user to use proper device data on different devices
    const storageDevice = sessionStorage.getItem(USER_SERVICE_KEYS.CURRENT_DEVICE);

    const urlDevice = deviceDetection();

    //Store data in session storage if it's not there yet
    if (!storageDevice) {
      sessionStorage.setItem(USER_SERVICE_KEYS.CURRENT_DEVICE, urlDevice);

      return urlDevice;
    }

    //Unless the browser is active, the device data will be taken from the session storage
    if (urlDevice !== storageDevice) {
      return storageDevice;
    }

    //Otherwise, the device data will be taken from the url
    return urlDevice;
  };

  static track = async () => {
    return await users.trackSession({
      device: this.detectUserDevice()
    });
  };

  static processFirebaseToken = token => {
    const existingToken = this.getFirebaseToken();

    if (!existingToken || existingToken !== token) {
      this.setItem(USER_SERVICE_KEYS.FIREBASE_TOKEN, token);
    }
  };

  static getFirebaseToken = () => {
    return this.getItem(USER_SERVICE_KEYS.FIREBASE_TOKEN);
  };

  static updateFirebaseToken = async ({ firebaseToken: responseToken }) => {
    const firebaseToken = this.getFirebaseToken();

    if (firebaseToken && firebaseToken !== responseToken) {
      return await users.updateUser({ firebaseToken });
    }
  };

  static clear = () => {
    const device = this.detectUserDevice();
    const firebaseToken = this.getFirebaseToken();

    this.storage.clear();
    this.tokenStorage.clear();

    sessionStorage.setItem(USER_SERVICE_KEYS.CURRENT_DEVICE, device);
    this.storage.setItem(USER_SERVICE_KEYS.FIREBASE_TOKEN, firebaseToken);
  };

  // This method is used to remove auth data from the storage when user logs out (case for several tabs and session storage usage)
  static onHasAccountChange = value => {
    if (value) {
      this.removeTokens();
      this.removeShadowUser();
    }
  };
}
