import { ArrayShim } from '@packages/helpers/core/shims/array-shim';
import { UserService } from '../../../services/user-service';
import { INTRODUCER_ATTRIBUTE_FIELDS } from '../../../helpers/account/constants';
import { createReducer } from '../../utils';
import * as ACCOUNT from './action-types';

const initialData = {
  password: '',
  email: '',
  newPassword: '',
  code: '',
  userId: null, // shadow user id
  displayName: null,
  shortDisplayName: null,
  profiles: {},
  businessProfilesLoading: true,
  businessProfilesError: false,
  businessProfileLoading: true,
  businessProfileError: false,
  termsAndPrivacy: false,
  emailNotifications: false,
  externalCode: '',
  loggingIn: true,
  loginError: '',
  signingIn: true,
  signingError: '',
  confirming: true,
  confirmingError: false,
  resendingCodeSignUp: false,
  resendingCodeSignUpError: false,
  resettingPassword: true,
  resettingPasswordError: '',
  resettingCode: false,
  resettingCodeError: '',
  loadingUser: true,
  loadingUserError: false,
  updatingPassword: true,
  updatingPasswordError: '',
  loggingOut: true,
  loggingOutError: true,
  deletingAccount: true,
  deletingAccountError: false,
  checkingEmailAvailability: true,
  checkingEmailAvailabilityError: '',
  checkingEmailValidity: true,
  checkingEmailValidityError: '',
  marketingListIds: [],
  loadingMarketingListIds: false,
  marketingListIdsError: '',
  introducer: 'default',
  currentAuth: {},
  hasAccount: UserService.hasAccount(),
  hasUser: UserService.hasUser()
};
// NOTE: introducer, segmentNumber, hasAccount, hasUser set both in account and userAttributes.user.
//       This is because userAttributes used for display conditions, but this data is more related to account.
//       Will be refactored in the future when refactoring display conditions LM-695.

export const account = createReducer(initialData, {
  [ACCOUNT.SET_ACCOUNT_DATA]: (state, { name, value }) => ({
    ...state,
    [name]: typeof value === 'function' ? value(state[name]) : value
  }),
  [ACCOUNT.LOGIN_REQUEST]: state => ({ ...state, loggingIn: true, loginError: '' }),
  [ACCOUNT.LOGIN_SUCCESS]: state => ({ ...state, password: '', loggingIn: false, hasAccount: false, hasUser: true }),
  [ACCOUNT.LOGIN_FAILURE]: (state, action) => ({ ...state, loggingIn: false, loginError: action.errorMsg }),
  [ACCOUNT.SIGN_UP_REQUEST]: state => ({ ...state, signingIn: true, signingError: '' }),
  [ACCOUNT.SIGN_UP_SUCCESS]: state => ({ ...state, signingIn: false, signingError: '' }),
  [ACCOUNT.SIGN_UP_FAILURE]: (state, action) => ({ ...state, signingIn: false, signingError: action.errorMsg }),
  [ACCOUNT.CONFIRM_SIGN_UP_REQUEST]: state => ({ ...state, confirming: true }),
  [ACCOUNT.CONFIRM_SIGN_UP_SUCCESS]: state => ({ ...state, confirming: false, hasAccount: true, hasUser: false }),
  [ACCOUNT.CONFIRM_SIGN_UP_FAILURE]: state => ({ ...state, confirming: false, confirmingError: true }),
  [ACCOUNT.RESEND_CODE_SIGN_UP_REQUEST]: state => ({
    ...state,
    resendingCodeSignUp: true,
    resendingCodeSignUpError: ''
  }),
  [ACCOUNT.RESEND_CODE_SIGN_UP_SUCCESS]: state => ({ ...state, resendingCodeSignUp: false }),
  [ACCOUNT.RESEND_CODE_SIGN_UP_FAILURE]: (state, { errorMsg }) => ({
    ...state,
    resendingCodeSignUp: false,
    resendingCodeSignUpError: errorMsg
  }),
  [ACCOUNT.RESET_CODE_REQUEST]: state => ({ ...state, resettingCode: true, resettingCodeError: '' }),
  [ACCOUNT.RESET_CODE_SUCCESS]: state => ({ ...state, resettingCode: false }),
  [ACCOUNT.RESET_CODE_FAILURE]: (state, { errorMsg }) => ({
    ...state,
    resettingCode: false,
    resettingCodeError: errorMsg
  }),
  [ACCOUNT.RESET_PASSWORD_REQUEST]: state => ({ ...state, resettingPassword: true, resettingPasswordError: '' }),
  [ACCOUNT.RESET_PASSWORD_SUCCESS]: state => ({ ...state, resettingPassword: false, resettingPasswordError: '' }),
  [ACCOUNT.RESET_PASSWORD_FAILURE]: (state, action) => ({
    ...state,
    resettingPassword: false,
    resettingPasswordError: action.errorMsg
  }),
  [ACCOUNT.GET_USER_REQUEST]: state => ({ ...state, loadingUser: true, loadingUserError: false }),
  [ACCOUNT.GET_USER_SUCCESS]: (
    state,
    {
      payload: {
        email,
        userId,
        segmentNumber,
        externalCode,
        introducerCode: introducer,
        introducer: introducerData,
        displayName,
        shortDisplayName,
        currentAuth
      }
    }
  ) => ({
    ...state,
    loadingUser: false,
    email,
    userId,
    segmentNumber,
    externalCode,
    displayName,
    shortDisplayName,
    currentAuth,
    [INTRODUCER_ATTRIBUTE_FIELDS.introducer]: introducer,
    [INTRODUCER_ATTRIBUTE_FIELDS.brokerName]: introducerData?.brokerName,
    [INTRODUCER_ATTRIBUTE_FIELDS.introducerImage]: introducerData?.image
  }),
  [ACCOUNT.GET_USER_FAILURE]: state => ({ ...state, loadingUser: false, loadingUserError: true }),
  [ACCOUNT.UPDATE_PASSWORD_REQUEST]: state => ({ ...state, updatingPassword: true, updatingPasswordError: '' }),
  [ACCOUNT.UPDATE_PASSWORD_SUCCESS]: state => ({ ...state, updatingPassword: false, updatingPasswordError: '' }),
  [ACCOUNT.UPDATE_PASSWORD_FAILURE]: (state, action) => ({
    ...state,
    updatingPassword: false,
    updatingPasswordError: action.errorMsg
  }),
  [ACCOUNT.LOG_OUT_REQUEST]: state => ({ ...state, loggingOut: true }),
  [ACCOUNT.LOG_OUT_SUCCESS]: () => ({ ...initialData, loggingOut: false, hasUser: false, hasAccount: true }),
  [ACCOUNT.LOG_OUT_FAILURE]: state => ({ ...state, loggingOut: false, loggingOutError: true }),
  [ACCOUNT.DELETE_ACCOUNT_REQUEST]: state => ({ ...state, deletingAccount: true }),
  [ACCOUNT.DELETE_ACCOUNT_SUCCESS]: () => ({ ...initialData, deletingAccount: false }),
  [ACCOUNT.DELETE_ACCOUNT_FAILURE]: state => ({ ...state, deletingAccount: false, deletingAccountError: true }),
  [ACCOUNT.DELETE_PROFILE_REQUEST]: state => ({ ...state, deletingAccount: true, deletingAccountError: false }),
  [ACCOUNT.DELETE_PROFILE_SUCCESS]: state => ({ ...state, deletingAccount: false, deletingAccountError: false }),
  [ACCOUNT.DELETE_PROFILE_FAILURE]: state => ({ ...state, deletingAccount: false, deletingAccountError: true }),
  [ACCOUNT.SET_ACCOUNT_ERROR]: (state, { name, errorMsg }) => ({ ...state, [name]: errorMsg }),
  [ACCOUNT.CHECK_EMAIL_AVAILABILITY_REQUEST]: state => ({ ...state, checkingEmailAvailability: true }),
  [ACCOUNT.CHECK_EMAIL_AVAILABILITY_SUCCESS]: state => ({
    ...state,
    checkingEmailAvailability: false,
    checkingEmailAvailabilityError: ''
  }),
  [ACCOUNT.CHECK_EMAIL_AVAILABILITY_FAILURE]: (state, { errorMsg }) => ({
    ...state,
    checkingEmailAvailability: false,
    checkingEmailAvailabilityError: errorMsg
  }),
  [ACCOUNT.CHECK_EMAIL_VALIDITY_REQUEST]: state => ({ ...state, checkingEmailValidity: true }),
  [ACCOUNT.CHECK_EMAIL_VALIDITY_SUCCESS]: state => ({
    ...state,
    checkingEmailValidity: false,
    checkingEmailValidityError: ''
  }),
  [ACCOUNT.CHECK_EMAIL_VALIDITY_FAILURE]: (state, { errorMsg }) => ({
    ...state,
    checkingEmailValidity: false,
    checkingEmailValidityError: errorMsg
  }),
  [ACCOUNT.GET_MARKETING_PREFERENCES_REQUEST]: state => ({ ...state, loadingMarketingListIds: true }),
  [ACCOUNT.GET_MARKETING_PREFERENCES_SUCCESS]: (state, action) => ({
    ...state,
    loadingMarketingListIds: false,
    marketingListIds: action.payload.map(({ id }) => Number(id)),
    marketingListIdsError: ''
  }),
  [ACCOUNT.GET_MARKETING_PREFERENCES_FAILURE]: (state, { errorMsg }) => ({
    ...state,
    loadingMarketingListIds: false,
    marketingListIdsError: errorMsg
  }),
  [ACCOUNT.SET_MARKETING_PREFERENCES_REQUEST]: state => ({ ...state, loadingMarketingListIds: true }),
  [ACCOUNT.SET_MARKETING_PREFERENCES_SUCCESS]: (state, action) => ({
    ...state,
    loadingMarketingListIds: false,
    marketingListIds: action.payload.map(({ marketingListId }) => Number(marketingListId)),
    marketingListIdsError: ''
  }),
  [ACCOUNT.SET_MARKETING_PREFERENCES_FAILURE]: (state, { errorMsg }) => ({
    ...state,
    loadingMarketingListIds: false,
    marketingListIdsError: errorMsg
  }),
  [ACCOUNT.UPDATE_MARKETING_PREFERENCES]: (state, { ids }) => ({
    ...state,
    marketingListIds: ids
  }),
  [ACCOUNT.CREATE_SHADOW_USER_REQUEST]: state => ({ ...state, loadingUser: true }),
  [ACCOUNT.CREATE_SHADOW_USER_SUCCESS]: (
    state,
    { payload: { email, userId, segmentNumber, externalCode, introducer: introducerData, introducerCode: introducer } }
  ) => ({
    ...state,
    loadingUser: false,
    hasAccount: false,
    hasUser: false,
    email,
    userId,
    segmentNumber,
    externalCode,
    [INTRODUCER_ATTRIBUTE_FIELDS.introducer]: introducer,
    [INTRODUCER_ATTRIBUTE_FIELDS.brokerName]: introducerData?.brokerName,
    [INTRODUCER_ATTRIBUTE_FIELDS.introducerImage]: introducerData?.image
  }),
  [ACCOUNT.UPDATE_USER_REQUEST]: state => ({ ...state, loadingUser: true }),
  [ACCOUNT.UPDATE_USER_SUCCESS]: (
    state,
    {
      payload: {
        email,
        userId,
        segmentNumber,
        externalCode,
        introducer: introducerData,
        introducerCode: introducer,
        displayName,
        shortDisplayName
      }
    }
  ) => ({
    ...state,
    loadingUser: false,
    hasAccount: false,
    hasUser: false,
    email,
    userId,
    segmentNumber,
    externalCode,
    displayName,
    shortDisplayName,
    [INTRODUCER_ATTRIBUTE_FIELDS.introducer]: introducer,
    [INTRODUCER_ATTRIBUTE_FIELDS.brokerName]: introducerData?.brokerName,
    [INTRODUCER_ATTRIBUTE_FIELDS.introducerImage]: introducerData?.image
  }),
  [ACCOUNT.GET_BUSINESS_PROFILES_REQUEST]: state => ({
    ...state,
    businessProfilesLoading: true,
    businessProfilesError: false
  }),
  [ACCOUNT.GET_BUSINESS_PROFILES_SUCCESS]: (state, { payload }) => {
    const profiles = ArrayShim.normalize(payload, 'userId');

    return {
      ...state,
      profiles,
      businessProfilesLoading: false,
      businessProfilesError: false
    };
  },
  [ACCOUNT.GET_BUSINESS_PROFILES_FAILURE]: state => ({
    ...state,
    businessProfilesLoading: false,
    businessProfilesError: true
  }),
  [ACCOUNT.SWITCH_BUSINESS_PROFILE_REQUEST]: (state, { payload: { userId, displayName, shortDisplayName } }) => ({
    ...state,
    userId,
    displayName,
    shortDisplayName,
    businessProfilesLoading: true,
    businessProfilesError: false
  }),
  [ACCOUNT.SWITCH_BUSINESS_PROFILE_SUCCESS]: state => ({
    ...state,
    businessProfilesLoading: false,
    businessProfilesError: false
  }),
  [ACCOUNT.SWITCH_BUSINESS_PROFILE_FAILURE]: state => ({
    ...state,
    businessProfilesLoading: false,
    businessProfilesError: true
  }),
  [ACCOUNT.GET_BUSINESS_PROFILE_REQUEST]: state => ({
    ...state,
    businessProfileLoading: true,
    businessProfileError: false
  }),
  [ACCOUNT.GET_BUSINESS_PROFILE_SUCCESS]: (state, { payload }) => {
    const { userId } = payload;

    return {
      ...state,
      profiles: {
        ...state.profiles,
        [userId]: { ...payload, displayName: payload.displayName ?? userId }
      },
      businessProfileLoading: false,
      businessProfileError: false
    };
  },
  [ACCOUNT.GET_BUSINESS_PROFILE_FAILURE]: state => ({
    ...state,
    businessProfileLoading: false,
    businessProfileError: true
  })
});
