import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose, defaultProps, withHandlers, withState } from 'recompose';
import { InputSelect } from '../../../components/inputs/select';
import { FHC_MODULE_STATUSES } from '../../../helpers/constants';
import { removeStatus, sendStatus } from '../../../store/reducers/status-data';
import styles from '../../../styles/templates/guidance-template.module.scss';
import { withLoadingHandlers, withSpinner } from '../../../components/with-loader';

const defaultActivityStatuses = [
  {
    id: FHC_MODULE_STATUSES.TO_DO,
    label: 'To Do',
    value: {
      key: FHC_MODULE_STATUSES.TO_DO,
      title: 'To Do'
    },
    disabled: false
  },
  {
    id: FHC_MODULE_STATUSES.NOT_APPLICABLE,
    label: 'Not applicable',
    value: {
      key: FHC_MODULE_STATUSES.NOT_APPLICABLE,
      title: 'Not applicable'
    },
    disabled: false
  },
  {
    id: FHC_MODULE_STATUSES.COMPLETED,
    label: 'Done',
    value: {
      key: FHC_MODULE_STATUSES.COMPLETED,
      title: 'Done'
    },
    disabled: false
  }
];

const Component = ({ handleChange, activityStatuses, selected }) => {
  const stopPropagation = e => e.stopPropagation();

  return (
    <div className={styles['action-select']} onClick={stopPropagation}>
      <InputSelect name='activity' value={selected} items={activityStatuses} onChange={handleChange} />
    </div>
  );
};

const ActionSelect = compose(
  connect(null, { sendStatus, removeStatus }),
  defaultProps({ activityStatuses: defaultActivityStatuses }),
  withState('selected', 'setSelected', ({ activityStatusKey }) => activityStatusKey),
  withLoadingHandlers(false),
  withHandlers({
    handleChange:
      ({ sendStatus, removeStatus, statusKey, turnOnLoading, turnOffLoading, setSelected, selected }) =>
      () =>
      async ({ key }) => {
        turnOnLoading();

        await removeStatus(statusKey, selected);
        switch (key) {
          case FHC_MODULE_STATUSES.COMPLETED:
            await sendStatus(statusKey, FHC_MODULE_STATUSES.COMPLETED);
            setSelected(FHC_MODULE_STATUSES.COMPLETED);
            break;
          case FHC_MODULE_STATUSES.TO_DO:
            await sendStatus(statusKey, FHC_MODULE_STATUSES.TO_DO);
            setSelected(FHC_MODULE_STATUSES.TO_DO);
            break;
          case FHC_MODULE_STATUSES.NOT_APPLICABLE:
            await sendStatus(statusKey, FHC_MODULE_STATUSES.NOT_APPLICABLE);
            setSelected(FHC_MODULE_STATUSES.NOT_APPLICABLE);
            break;
          default:
            break;
        }

        turnOffLoading();
      }
  }),
  withSpinner
)(Component);

ActionSelect.propTypes = {
  statusKey: PropTypes.string.isRequired,
  activityStatusKey: PropTypes.oneOf(Object.values(FHC_MODULE_STATUSES)).isRequired,
  activityStatuses: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOf(Object.values(FHC_MODULE_STATUSES)).isRequired,
      label: PropTypes.string.isRequired,
      value: PropTypes.shape({
        key: PropTypes.oneOf(Object.values(FHC_MODULE_STATUSES)).isRequired,
        title: PropTypes.string.isRequired
      }),
      disabled: PropTypes.bool
    })
  )
};

export default ActionSelect;
