// TODO: replace object with handler generatePlainEnumLikeObject after merge:
// https://bitbucket.org/life-moments/lifehub-monorepo/pull-requests/3431/overview
const REQUIRED_AUTH_SERVICE_KEYS = {
  redirectUrl: 'redirectUrl',
  authProvider: 'authProvider',
  authUrl: 'authUrl'
};

export class RequiredAuthService {
  static #storage = localStorage;

  static #setItem = (key, value) => {
    this.#storage.setItem(key, value);
  };

  static #getItem = key => {
    return this.#storage.getItem(key);
  };

  static #removeItem = key => {
    this.#storage.removeItem(key);
  };

  static setRedirectUrl = redirectUrl => {
    this.#setItem(REQUIRED_AUTH_SERVICE_KEYS.redirectUrl, redirectUrl);
  };

  static getRedirectUrl = () => {
    return this.#getItem(REQUIRED_AUTH_SERVICE_KEYS.redirectUrl);
  };

  static setAuthProvider = currentProvider => {
    this.#setItem(REQUIRED_AUTH_SERVICE_KEYS.authProvider, currentProvider);
  };

  static getAuthProvider = () => {
    return this.#getItem(REQUIRED_AUTH_SERVICE_KEYS.authProvider);
  };

  static removeAuthProvider = () => {
    this.#removeItem(REQUIRED_AUTH_SERVICE_KEYS.authProvider);
  };

  static setAuthUrl = authUrl => {
    this.#setItem(REQUIRED_AUTH_SERVICE_KEYS.authUrl, authUrl);
  };

  static getAuthUrl = () => {
    return this.#getItem(REQUIRED_AUTH_SERVICE_KEYS.authUrl);
  };

  static clearStorage = () => {
    this.#removeItem(REQUIRED_AUTH_SERVICE_KEYS.redirectUrl);
    this.#removeItem(REQUIRED_AUTH_SERVICE_KEYS.authUrl);
  };
}
