import { parse } from 'query-string';
import { api } from '../../api';
import { exclude, withSearchParams } from '../../helpers/navigation/routing';
import { RequiredAuthService } from '../../services/required-auth-service';
import { UserService } from '../../services/user-service';

export const DEFAULT_AUTH_PROVIDER = 'default';

export const getSearchCustomAuthToken = () => parse(window.location.search).custom_auth;
export const getSearchOriginName = () => parse(window.location.search).origin;

/**
 * Retrieves the authentication properties from the environment.
 */
export const getEnvAuthProps = () => {
  const customAuthToken = process.env.REACT_APP_CUSTOM_AUTH_TOKEN;
  const authRequired = process.env.REACT_APP_AUTH_REQUIRED === 'true';
  const defaultAuthProvider = process.env.REACT_APP_DEFAULT_AUTH_PROVIDER;

  return { customAuthToken, authRequired, defaultAuthProvider };
};

/**
 * Helper to determine if authentication is required based on user status, environment, or backdoor token.
 */
export const shouldSkipAuth = () => {
  const hasUser = UserService.hasUser();
  const customAuthSearch = getSearchCustomAuthToken();
  const origin = getSearchOriginName();
  const { customAuthToken, authRequired } = getEnvAuthProps();

  return (!authRequired && !origin) || hasUser || (customAuthToken && customAuthToken === customAuthSearch);
};

/**
 * Handles the scenario where a custom backdoor token is used to bypass authentication.
 */
export const handleBackdoorAuth = () => {
  const customAuthSearch = getSearchCustomAuthToken();

  // In case for a backdoor - remove the user auth data
  if (customAuthSearch) {
    UserService.removeTokens();
  }
};

/**
 * Redirects the user to the authentication URL.
 */
export const redirectToAuthUrl = authUrl => {
  // Redirect to the provider’s authentication URL
  window.location.href = authUrl;
};

/**
 * Retrieves the appropriate authentication provider, prioritizing 'origin' if present.
 */
export const getAuthProvider = () => {
  const origin = getSearchOriginName();
  const { defaultAuthProvider } = getEnvAuthProps();
  const storageAuthProvider = RequiredAuthService.getAuthProvider();

  return origin ?? storageAuthProvider ?? defaultAuthProvider ?? DEFAULT_AUTH_PROVIDER;
};

/**
 * Retrieves the authentication URL for the specified auth provider.
 */
export const getRedirectAuthUrl = async authProvider => {
  try {
    const { authUrl, provider } = await api.sso.getAuthUrl(authProvider);

    if (!authUrl) {
      return { authUrl: null, error: `Authorisation link is not found for provider: '${authProvider}'` };
    }

    saveAuthAndRedirectUrls(provider, authUrl);

    return { authUrl, error: null };
  } catch (error) {
    return { authUrl: null, error: `Error getting Authorisation link, try later: ${error.message}` };
  }
};

/**
 * Saves the provider's authorization URL and the current page's redirect URL for future use.
 */
export const saveAuthAndRedirectUrls = (provider, authUrl) => {
  const redirectUrl = withSearchParams(window.location.pathname, window.location.search, exclude(['origin']));
  RequiredAuthService.setAuthUrl(authUrl);
  RequiredAuthService.setAuthProvider(provider);
  RequiredAuthService.setRedirectUrl(redirectUrl);
};

/**
 * Sends the callback data to the server to authenticate the user.
 */
export const sendCallbackData = async search => {
  try {
    const data = await api.sso.getAuthCallback(search);

    if (!data?.token) {
      return { authData: null, error: `Error getting callback data. ${data.message}` };
    }

    return { authData: data, error: null };
  } catch (error) {
    return { authData: null, error: `Error getting callback data, try later: ${error.message}` };
  }
};
