import { configuration } from './configuration';

const CONFIGURATION_IDENTIFIER = 'configuration';

export const getConfig = async introducerCode => {
  const thenHandler = response => {
    if (!response.ok) {
      return {};
    }

    return response.json();
  };

  return await configuration.getDataDictionary({ type: CONFIGURATION_IDENTIFIER, introducerCode }).then(thenHandler);
};

export const setGlobalConfig = async (introducerCode = 'default') => {
  window.CONFIG = await getConfig(introducerCode);
};
